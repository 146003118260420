
import { useForm, isNotEmpty } from '@mantine/form';
import { Button, Group, TextInput, Modal, Textarea } from '@mantine/core';
import { AppContext } from '../../../context/context';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { showNotification } from '@mantine/notifications';

const FormCorreos = ({ id, reloadtable, nombreArchivo }) => {

    const appcontext = useContext(AppContext);
    const [title, setTitle] = useState('Nuevo')

    useEffect(() => {
        if (id != '' && appcontext.openedFCC) {
            getDataFromId(id);
            setTitle('Editar cadena de distribucion');
        }
    }, [appcontext.openedFCC]);

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            CODIGO: '',
            NOMBRE_ARCHIVO: '',
            TO: '',
            CC: ''
        },

        validate: {
            TO: isNotEmpty('Debes ingresar un valor'),
            CC: isNotEmpty('Debes ingresar un valor'),
        },
    });

    const handleClickUpdate = async (values) => {
        try {
            values.USUARIO_ACTUALIZACION = sessionStorage.getItem("user");
            values.NOMBRE_ARCHIVO = nombreArchivo;            
            if (typeof (values.TO) == 'string') {
                values.TO = values.TO.split(',');
            }
            if (typeof (values.CC) == 'string') {
                values.CC = values.CC.split(',');
            }
            
             await axios.put(process.env.REACT_APP_API_URL_DE + `/api/correos/${id}`, values,{
                 headers: {
                     Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
                 }
             });
            showNotification({
                title: 'Listo!',
                message: 'Correos del cliente actualizados con exito',
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#25A661',
                        borderColor: '#25A661',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
            reloadtable('Correos de clientes');
            appcontext.setStatusFCC(false);
        } catch (err) {
            showNotification({
                title: 'Error!',
                message: err.response?.data?.error ? err.response.data.error : err.message,
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#E93F19',
                        borderColor: 'E93F19',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
        }

    }

    const getDataFromId = async (id) => {
        try {
            var response = await axios.post(process.env.REACT_APP_API_URL_DE + '/api/correos/oneclient', { id: id }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
                }
            });

            let result = response.data;
            var x = result.ENVIO_CORREO.filter(item => item.NOMBRE_ARCHIVO == nombreArchivo);
            form.setValues({
                CODIGO: result.CODIGO,
                NOMBRE_ARCHIVO: x[0].NOMBRE_ARCHIVO,
                TO: x[0].TO ? x[0].TO : "",
                CC: x[0].CC ? x[0].CC : "",
            });
        } catch (err) {
            showNotification({
                title: 'Error!',
                message: err.response?.data?.error ? err.response.data.error : err.message,
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#E93F19',
                        borderColor: 'E93F19',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
        }
    }

    return (
        <Modal opened={appcontext.openedFCC} onClose={() => { appcontext.setStatusFCC(false); }} title={title}>
            <form onSubmit={form.onSubmit((values) => { handleClickUpdate(values) })}>
                <TextInput
                    disabled
                    label="Codigo"
                    key={form.key('CODIGO')}
                    {...form.getInputProps('CODIGO')}
                />
                <TextInput
                    label="Nombre del archivo"
                    mt="md"
                    disabled
                    key={form.key('NOMBRE_ARCHIVO')}
                    {...form.getInputProps('NOMBRE_ARCHIVO')}
                />
                <Textarea
                    label="Para"
                    placeholder="Correos separados por una ',' "
                    autosize
                    mt="md"
                    withAsterisk
                    minRows={2}
                    maxRows={4}
                    key={form.key('TO')}
                    {...form.getInputProps('TO')}
                />
                <Textarea
                    label="Copia"
                    placeholder="Correos separados por una ','"
                    autosize
                    mt="md"
                    withAsterisk
                    minRows={2}
                    maxRows={4}
                    key={form.key('CC')}
                    {...form.getInputProps('CC')}
                />
                <Group justify="flex-end" mt="md">
                    <Button type="button" variant='outline' onClick={() => { appcontext.setStatusFCC(false); }}>Cerrar</Button>
                    <Button type="submit">Guardar</Button>
                </Group>
            </form>
        </Modal>
    );

}

export default FormCorreos;