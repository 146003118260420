import { Center, Grid, Button } from '@mantine/core';
import classes from './Error.module.css';
import HeaderLogin from '../Shared/HeaderLogin/HeaderLogin';
import Footer from '../Shared/Footer/Footer'
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();
  const handleclick=()=>{
    navigate('/Home');
  }
  return (
    <>
      <HeaderLogin />
      <Center className={classes.wrapper} >
        <Grid>
          <Grid.Col span={12}><h1>Disculpa las molestias, esta página no está disponible.</h1></Grid.Col>          
          <Grid.Col span={12}><p>¡Dejame ayudarte a regresar a la página de inicio :D!</p></Grid.Col>          
          <Button  mt="xl" size="md" className={classes.amprimary} onClick={handleclick}>
            Regresar al inicio
          </Button>                 
        </Grid>
      </Center>
      <Footer />
    </>
  );
}

export default ErrorPage
