import DataTableComponent from '../CustomComponents/DataTable';
import { Card, Grid, Text, Button, Group, Autocomplete, Box, LoadingOverlay, Title } from '@mantine/core';
import classes from './Catalogos.module.css';
import { AppContext } from '../../context/context';
import { useState, useContext, useEffect } from 'react';
import FormCliente from './FormCliente/FormCliente';
import FormCodigoSky from './FormCodigosSky/FormCodigoSky';
import FormCorreos from './FormCorreos/FormCorreos';
import FormConectionStatus from './FormConectionStatus/FormConectionStatus';
import FormDesviacion from './FormDesviacion/FormDesviacion';
import FormCarrier from './FormCarrier/FormCarrier';
import { showNotification } from '@mantine/notifications';


const catalogos = [
    { nombre: 'Clientes', endpoint: 'clientes' },
    { nombre: 'Codigos Sky', endpoint: 'codigosSky' },
    { nombre: 'Correos de clientes', endpoint: 'correos' },
    { nombre: 'Conexion Status', endpoint: 'codigosConexion' },
    { nombre: 'Code Desviation', endpoint: 'codigosDesviacion' },
    { nombre: 'Carrier', endpoint: 'carriers' }
];

const Catalogos = () => {
    const appcontext = useContext(AppContext);
    const [selectedValue, setSelectedValue] = useState('');
    const [catalogo, setCatalogo] = useState('');
    const [dataofTable, setDataofTable] = useState([]);
    const [visible, setIsVisible] = useState(false);
    const [id, setId] = useState('');
    const [nombreArchivo, setNombreArchivo] = useState('');

    useEffect(() => {
        if (appcontext.initialCatalogo !== '') {
            getDataofTable(appcontext.initialCatalogo);
        }
    }, []);

    const handleChange = (value) => {
        setSelectedValue(value);
    };

    const handleClick = (e) => {
        getDataofTable('');
    }

    const handleOnFocus = (e) => {
        setSelectedValue('');
    }

    const getDataofTable = async (value) => {
        try {
            let endpoint = ''
            if (value === '') {
                endpoint = catalogos.find(item => item.nombre === selectedValue);
                appcontext.setInitialCatalog(selectedValue);
            }
            else {
                endpoint = catalogos.find(item => item.nombre === value);
                appcontext.setInitialCatalog(value);
            }
            if (endpoint == null) {
                throw new Error('Elige una opcion valida');
            }
            setIsVisible(true);
            //const response = await fetch(process.env.REACT_APP_API_URL + '/api/' + endpoint.endpoint);
            const response = await fetch(process.env.REACT_APP_API_URL_DE + '/api/' + endpoint.endpoint, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            let result = await response.json();
            switch (endpoint.nombre) {
                case "Correos de clientes":
                    let tmp = [];
                    result.forEach(item => {
                        if (item.hasOwnProperty('ENVIO_CORREO')) {
                            item['ENVIO_CORREO'].forEach(subitem => {
                                let obj = {
                                    "_id": item['_id'],
                                    "CLIENTE": item['CODIGO'],
                                    "NOMBRE ARCHIVO": subitem['NOMBRE_ARCHIVO'],
                                    "PARA": subitem['TO'] ? subitem['TO'].join(',\n') : "",
                                    "COPIA": subitem['CC'] ? subitem['CC'].join(',\n') : "",
                                };
                                tmp.push(obj);
                            })
                        }
                    });
                    result = tmp;
                    break;
                default:
                    break;
            }
            setDataofTable(result);
            setCatalogo(value === '' ? selectedValue : value);
        } catch (err) {
            showNotification({
                title: 'Error!',
                message: err.response?.data?.error ? err.response.data.error : err.message,
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#E93F19',
                        borderColor: 'E93F19',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
        } finally {
            setIsVisible(false);
        }
    }



    return (
        <Box pos="relative">
            <LoadingOverlay
                visible={visible}
                zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ color: '#FA0073', type: 'bars', size: '200' }}
            />
            <div className={classes.main}>
                <Grid margin="md">
                    <Grid.Col span={12}>
                        <Group justify="center">
                            <Title order={2}>Catalogos</Title>
                        </Group>
                    </Grid.Col>
                    <Grid.Col span={8} offset={2}>
                        <Card shadow="sm" padding="lg" radius="md" withBorder>
                            <Group justify="center" mt="md">
                                <Text fw={500}>Tipo de catalogo</Text>
                                <Autocomplete
                                    placeholder="Selecciona una opcion"
                                    data={catalogos.map(item => item.nombre)}
                                    value={selectedValue}
                                    onChange={handleChange}
                                    onFocus={handleOnFocus}
                                />
                                <Button variant="outline" color='#035CF7' onClick={handleClick}>
                                    Buscar
                                </Button>
                            </Group>
                        </Card>
                    </Grid.Col>
                    <Grid.Col>
                        {dataofTable.length === 0 ? "" :
                            <div style={{ padding: '20px' }}>
                                <DataTableComponent data={dataofTable} setId={setId} reloadtable={getDataofTable} setNombreArchivo={setNombreArchivo} selectedv={catalogo} />
                            </div>
                        }
                        <FormCliente id={id} reloadtable={getDataofTable} />
                        <FormCodigoSky id={id} reloadtable={getDataofTable} />
                        <FormCorreos id={id} reloadtable={getDataofTable} nombreArchivo={nombreArchivo} />
                        <FormConectionStatus id={id} reloadtable={getDataofTable} />
                        <FormDesviacion id={id} reloadtable={getDataofTable} />
                        <FormCarrier id={id} reloadtable={getDataofTable} />
                    </Grid.Col>
                </Grid>
            </div>
        </Box>

    );
}

export default Catalogos