import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { AppShell, Burger, Group, Button, Box, LoadingOverlay, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './ProtectedRoutes.module.css';
import logo from '../../content/Images/aeromex_logo.svg';
import { faIdCardClip, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

const ProtectedRoute = () => {
    const { isAuthenticated, isLoading } = useAuth0();
    const [opened, { toggle }] = useDisclosure();
    const navigate = useNavigate();
    const { logout } = useAuth0();
    const handleclick = (ruta) => {
        navigate('/' + ruta);
    }

    const cierre_sesion = () =>{
        console.log("cierra sesion")
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        logout();
      }

    console.log(sessionStorage.getItem("token"))
    if (isLoading) {
        // Optionally show a loading spinner or message
        return (<Box pos="relative">
            <LoadingOverlay visible={true} zIndex={1000} overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ color: '#FA0073', type: 'bars', size: '200' }} />
        </Box>)
    }
    //return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
    return (isAuthenticated || sessionStorage.getItem("token") != null) ? (

        <AppShell
            header={{ height: 60 }}
            navbar={{ width: 300, breakpoint: 'sm', collapsed: { desktop: true, mobile: !opened } }}
            padding="md"
        >
            <AppShell.Header className={classes.navbar}>
                <Group h="100%" px="md">
                    <Burger color="#fff" opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
                    <Group justify="space-between" style={{ flex: 1 }}>
                        <img src={logo} alt="logo" className={classes.aeroLogo} />
                        <Group ml="xl" gap={5} visibleFrom="sm">
                            <Button variant='subtle' color='#fff' onClick={() => handleclick('Home')}>Home</Button>
                            <Button variant='subtle' color='#fff' onClick={() => handleclick('Catalogos')}>Catalogos</Button>
                            <Button variant='subtle' color='#fff' onClick={() => handleclick('ReporteEmbarques')}>Reporte Embarques</Button>
                            <Button variant='subtle' color='#fff' onClick={() => handleclick('Interlineales')}>Interlineales</Button>
                            <Menu>
                                <Menu.Target>
                                    <Button variant='subtle'><FontAwesomeIcon icon={faIdCardClip} size='2x' color='#FFF' /></Button>
                                </Menu.Target>

                                <Menu.Dropdown>
                                    <Menu.Label>Usuario/Tipo</Menu.Label>
                                    <Menu.Item>
                                        {sessionStorage.getItem("user")}
                                    </Menu.Item>
                                    <Menu.Item
                                        color="red"
                                        leftSection={<FontAwesomeIcon icon={faRightFromBracket} />}
                                        onClick={() => cierre_sesion()}
                                    >
                                        Cerrar sesion
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        </Group>
                    </Group>
                </Group>
            </AppShell.Header>

            <AppShell.Navbar py="md" px="md" >
                <Button my={4} variant='subtle' color='#020C41'  onClick={() => handleclick('Home')}>Home</Button>
                <Button my={4} variant='subtle' color='#020C41'  onClick={() => handleclick('Catalogos')}>Catalogos</Button>
                <Button my={4} variant='subtle' color='#020C41'  onClick={() => handleclick('ReporteEmbarques')}>Reporte</Button>
                <Button my={4} variant='subtle' color='#020C41' onClick={() => handleclick('Interlineales')}> Interlineales</Button>
                <Menu>
                                <Menu.Target>
                                    <Button variant='subtle'><FontAwesomeIcon icon={faIdCardClip} size='2x' color='#020C41' /></Button>
                                </Menu.Target>

                                <Menu.Dropdown>
                                    <Menu.Label>Usuario</Menu.Label>
                                    <Menu.Item>
                                        UserName
                                    </Menu.Item>
                                    <Menu.Item
                                        color="red"
                                        leftSection={<FontAwesomeIcon icon={faRightFromBracket} />}
                                        onClick={() => logout()}
                                    >
                                        Cerrar sesion
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
            </AppShell.Navbar>

            <AppShell.Main>                
                <Outlet />
            </AppShell.Main>
        </AppShell>
    ) : <Navigate to="/" />;
};

export default ProtectedRoute;