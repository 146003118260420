import React, { useState } from 'react';
import { Table, Title, Pagination, Select, Group, TextInput, Grid, Divider } from '@mantine/core';

const DataTableComponentReportes = ({ data, codigo, fecha }) => {

    const columns = React.useMemo(() => {
        if (data.length === 0) return [];
        var biggest = 0;
        var index = 0;
        for (var i = 0; i < data.length; i++) {
            if (Object.values(data[i]).length > biggest) {
                biggest = Object.values(data[i]).length;
                index = i;
            }
        }
        var temp = Object.keys(data[index]);
        return temp;
    }, [data]);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [filter, setFilter] = useState('');

    const filteredData = data.filter(item =>
        Object.values(item).some(value =>
            value.toString().toLowerCase().includes(filter.toLowerCase())
        )
    );
    const totalPages = Math.ceil(filteredData.length / pageSize);
    const startIndex = (page - 1) * pageSize;
    const paginatedData = filteredData.slice(startIndex, startIndex + pageSize);
    const totalRecords = filteredData.length;
    const endIndex = Math.min(startIndex + pageSize - 1, totalRecords);

    return (
        <>
            <Group justify="space-between">
                <Select
                    label="Registros por pagina"
                    value={pageSize.toString()}
                    onChange={(value) => setPageSize(parseInt(value, 10))}
                    data={[5, 10, 20, 30, 50].map((size) => ({ value: size.toString(), label: size.toString() }))}
                />
                <Title order={3}>{codigo} - Fecha carga: {fecha.substring(0,10)}</Title>
                <TextInput
                    label="Buscar"
                    placeholder="Buscar..."
                    value={filter}
                    onChange={(event) => { setFilter(event.currentTarget.value); setPage(1) }}
                />
            </Group>
            <Grid.Col span={12}>
                <Divider size="sm" />
            </Grid.Col>

            <Table.ScrollContainer minWidth={500} type="native">
                <Table striped highlightOnHover withTableBorder>
                    <Table.Thead >
                        <Table.Tr>
                            {columns.map(column => (
                                <Table.Th key={column} style={{ backgroundColor: '#035cf7', color: '#F2F2F0' }}>
                                    {column}
                                </Table.Th>
                            ))}
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {
                            paginatedData.map((row, rowIndex) => (
                                <Table.Tr key={rowIndex}>
                                    {columns.map(column => {
                                        return (
                                            <Table.Td key={column}>
                                                {row[column]}
                                            </Table.Td>
                                        );
                                    }
                                    )}
                                </Table.Tr>
                            ))
                        }
                    </Table.Tbody>
                </Table>
            </Table.ScrollContainer >
            <Grid.Col span={12}>
                <Divider size="sm" />
            </Grid.Col>
            <Group justify="space-between">
                <Pagination
                    total={totalPages}
                    page={page}
                    onChange={setPage}
                    styles={{ control: { margin: '0 5px' } }}
                />
                <div style={{ marginTop: '10px' }}>
                    Showing {startIndex + 1} to {(endIndex + 1) > totalRecords ? totalRecords : endIndex + 1} of {totalRecords} entries
                </div>
            </Group>
        </>
    );
};

export default DataTableComponentReportes;