import React, { useState, useContext } from 'react';
import { Table, Title, Pagination, Select, Group, TextInput, Grid, Divider, Button } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { showNotification } from '@mantine/notifications';

const truestatus = [
    { 'clave':0, 'valor':"Disponible" },
    { 'clave':1, 'valor':"En proceso de llendado" },
    { 'clave':2, 'valor':"Error en llenado" }    
]

const DataTableEstatus = ({ data, setIsVisible, setMailData, setMailOpened, setSelectedValue }) => {
    
    const columns = React.useMemo(() => {
        var temp = Object.keys(data[0]);
        return temp;
    }, [data]);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [filter, setFilter] = useState('');

    const filteredData = data.filter(item =>
        Object.values(item).some(value =>
            value.toString().toLowerCase().includes(filter.toLowerCase())
        )
    );
    const totalPages = Math.ceil(filteredData.length / pageSize);
    const startIndex = (page - 1) * pageSize;
    const paginatedData = filteredData.slice(startIndex, startIndex + pageSize);
    const totalRecords = filteredData.length;
    const endIndex = Math.min(startIndex + pageSize - 1, totalRecords);

    const handleGetMailData = async (id) => {
        try {
            setSelectedValue(id)
            setIsVisible(true);
            const response = await fetch(process.env.REACT_APP_API_URL_DE + `/api/reporteEmbarques/getCorreosReporte/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            let result = await response.json();
            setMailData(result)
            setMailOpened(true)
        } catch (err) {
            showNotification({
                title: 'Error!',
                message: err.response?.data?.error ? err.response.data.error : err.message,
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#E93F19',
                        borderColor: 'E93F19',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
        } finally {
            setIsVisible(false);
        }
    }
    return (
        <>
            <Group justify="space-between">
                <Select
                    label="Registros por pagina"
                    value={pageSize.toString()}
                    onChange={(value) => setPageSize(parseInt(value, 10))}
                    data={[5, 10, 20, 30, 50].map((size) => ({ value: size.toString(), label: size.toString() }))}
                />
                <Title order={3}>Estatus Ejecuciones</Title>
                <TextInput
                    label="Buscar"
                    placeholder="Buscar..."
                    value={filter}
                    onChange={(event) => { setFilter(event.currentTarget.value); setPage(1) }}
                />
            </Group>
            <Grid.Col span={12}>
                <Divider size="sm" />
            </Grid.Col>

            <Table.ScrollContainer minWidth={500} type="native">
                <Table striped highlightOnHover withTableBorder>
                    <Table.Thead >
                        <Table.Tr>
                            {columns.map(column => (
                                <Table.Th key={column} style={{ backgroundColor: '#035cf7', color: '#F2F2F0' }}>
                                    {column}
                                </Table.Th>
                            ))}
                            <Table.Th style={{ backgroundColor: '#035cf7', color: '#F2F2F0' }}>
                                Acciones
                            </Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {
                            paginatedData.map((row, rowIndex) => (
                                <Table.Tr key={rowIndex}>
                                    {columns.map(column => {
                                        return (
                                            <Table.Td key={column}>
                                                {column == "ESTATUS" ? truestatus.filter(item => item.clave == row[column]).map(c=>c.valor) : row[column]}
                                            </Table.Td>
                                        );
                                    }
                                    )}
                                    <Table.Td>
                                        <Button.Group>
                                            <Button variant="outline" color='#035CF7' onClick={() => { handleGetMailData(row["id"]) }} disabled={row["ESTATUS"]==0?false:true} ><FontAwesomeIcon icon={faEnvelope} /></Button>
                                        </Button.Group>
                                    </Table.Td>
                                </Table.Tr>
                            ))
                        }
                    </Table.Tbody>
                </Table>
            </Table.ScrollContainer >
            <Grid.Col span={12}>
                <Divider size="sm" />
            </Grid.Col>
            <Group justify="space-between">
                <Pagination
                    total={totalPages}
                    page={page}
                    onChange={setPage}
                    styles={{ control: { margin: '0 5px' } }}
                />
                <div style={{ marginTop: '10px' }}>
                    Showing {startIndex + 1} to {(endIndex + 1) > totalRecords ? totalRecords : endIndex + 1} of {totalRecords} entries
                </div>
            </Group>
        </>
    );
};

export default DataTableEstatus;