
import { useForm, isNotEmpty } from '@mantine/form';
import { Button, Group, TextInput, Modal } from '@mantine/core';
import { AppContext } from '../../../context/context';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { showNotification } from '@mantine/notifications';

const FormConectionStatus = ({ id, reloadtable }) => {

  const appcontext = useContext(AppContext);
  const [title, setTitle] = useState('Nuevo')

  useEffect(() => {
    if (id != '' && appcontext.openedFCCS) {
      getDataFromId(id);
      setTitle('Editar codigo status de conexion');
    } else {
      setTitle('Nuevo codigo status de conexion');
      form.reset();
    }
  }, [appcontext.openedFCCS]);

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      DESCRIPCION: '',
      COMENTARIO: ''
    },

    validate: {
      DESCRIPCION: isNotEmpty('Debes ingresar un valor')
    },
  });


  const handleClickNew = async (values) => {
    try {      
      values.USUARIO_ACTUALIZACION = sessionStorage.getItem("user");
      await axios.post(process.env.REACT_APP_API_URL_DE + '/api/codigosConexion', values, {headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
      }});
      showNotification({
        title: 'Listo!',
        message: 'Codigo de conexion guardado con exito',
        color: '#fff',
        styles: (theme) => ({
          root: {
            backgroundColor: '#25A661',
            borderColor: '#25A661',
            '&::before': { backgroundColor: theme.white },
          },
          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
          },
        }),
        autoClose: false,
      })     
      reloadtable('Conexion Status');
      form.reset();
    } catch (err) {
      showNotification({
        title: 'Error!',
        message: err.response?.data?.error ? err.response.data.error : err.message,
        color: '#fff',
        styles: (theme) => ({
          root: {
            backgroundColor: '#E93F19',
            borderColor: 'E93F19',
            '&::before': { backgroundColor: theme.white },
          },
          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
          },
        }),
        autoClose: false,
      })    
    }
  }

  const handleClickUpdate = async (values) => {
    try {
      values.USUARIO_ACTUALIZACION = sessionStorage.getItem("user");
      await axios.put(process.env.REACT_APP_API_URL_DE + `/api/codigosConexion/${id}`, values, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
        }
      });
      showNotification({
        title: 'Listo!',
        message: 'Codigo de conexion actualizado con exito',
        color: '#fff',
        styles: (theme) => ({
          root: {
            backgroundColor: '#25A661',
            borderColor: '#25A661',
            '&::before': { backgroundColor: theme.white },
          },
          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
          },
        }),
        autoClose: false,
      })      
      reloadtable('Conexion Status');
      appcontext.setStatusFCCS(false);
    } catch (err) {
      showNotification({
        title: 'Error!',
        message: err.response?.data?.error ? err.response.data.error : err.message,
        styles: (theme) => ({
          root: {
            backgroundColor: '#E93F19',
            borderColor: 'E93F19',
            '&::before': { backgroundColor: theme.white },
          },
          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
          },
        }),
        autoClose: false,
      })      
    }

  }

  const getDataFromId = async (id) => {
    try {
      var response=await axios.post(process.env.REACT_APP_API_URL_DE+'/api/codigosConexion/onecode', {id:id}, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
        },
      });      
      let result= response.data;
      form.setValues({
        DESCRIPCION: result.DESCRIPCION,
        COMENTARIO: result.COMENTARIO
      });
    } catch (err) {
      showNotification({
        title: 'Error!',
        message: err.response.data.error ? err.response.data.error : err.message,
        styles: (theme) => ({
          root: {
            backgroundColor: '#E93F19',
            borderColor: 'E93F19',
            '&::before': { backgroundColor: theme.white },
          },
          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
          },
        }),
        autoClose: false,
      })      
    }
  }

  return (
    <Modal opened={appcontext.openedFCCS} onClose={() => { appcontext.setStatusFCCS(false); }} title={title}>
      <form onSubmit={form.onSubmit((values) => { id == '' ? handleClickNew(values) : handleClickUpdate(values) })}>
        <TextInput
          label="Descripcion"
          placeholder="Descripcion del codigo"
          mt="md"
          withAsterisk
          key={form.key('DESCRIPCION')}
          {...form.getInputProps('DESCRIPCION')}
        />
        <TextInput
          label="Comentario"
          placeholder="Comentario"
          mt="md"
          key={form.key('COMENTARIO')}
          {...form.getInputProps('COMENTARIO')}
        />
        <Group justify="flex-end" mt="md">
          <Button type="button" variant='outline' onClick={() => { appcontext.setStatusFCCS(false); }}>Cerrar</Button>
          <Button type="submit">Guardar</Button>
        </Group>
      </form>
    </Modal>
  );

}

export default FormConectionStatus;