import classes from './Footer.module.css'

const Footer = () => {
    return (
        <div className={classes.footer}>
            <p className={classes.center}>©Todos los derechos reservados Aeromexico-Cargo México 2024.</p>
        </div>
    );
}

export default Footer;
