import React, { useState, useContext } from 'react';
import { AppContext } from '../../context/context';
import { Table, Button, Group, Select, TextInput, Grid, Divider, Pagination, Title } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPen, faLock } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { showNotification } from '@mantine/notifications';


const DataTableComponent = ({ data, setId, reloadtable, setNombreArchivo, selectedv }) => {

    const appcontext = useContext(AppContext);

    const columns = React.useMemo(() => {
        if (data.length === 0) return [];
        let temp = Object.keys(data[0]);
        temp.push("ACCIONES")
        return temp.slice(1);
    }, [data]);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [filter, setFilter] = useState('');

    const filteredData = data.filter(item =>
        Object.values(item).some(value =>
            value.toString().toLowerCase().includes(filter.toLowerCase())
        )
    );
    const totalPages = Math.ceil(filteredData.length / pageSize);
    const startIndex = (page - 1) * pageSize;
    const paginatedData = filteredData.slice(startIndex, startIndex + pageSize);
    const totalRecords = filteredData.length;
    const endIndex = Math.min(startIndex + pageSize - 1, totalRecords);

    const handleOpenForm = () => {
        switch (appcontext.initialCatalogo) {
            case 'Clientes':
                setId('');
                appcontext.setStatusFCN(true);
                break;
            case 'Codigos Sky':
                setId('');
                appcontext.setStatusFCS(true);
                break;
            case 'Conexion Status':
                setId('');
                appcontext.setStatusFCCS(true);
                break;
            case 'Code Desviation':
                setId('');
                appcontext.setStatusFCCD(true);
                break;
            case 'Carrier':
                setId('');
                appcontext.setStatusFCCC(true);
                break;
            default:
                break;
        }

    }

    const handleEdit = (id) => {
        switch (appcontext.initialCatalogo) {
            case 'Clientes':
                setId(id);
                appcontext.setStatusFCN(true);
                break;
            case 'Codigos Sky':
                setId(id);
                appcontext.setStatusFCS(true);
                break;
            case 'Conexion Status':
                setId(id);
                appcontext.setStatusFCCS(true);
                break;
            case 'Code Desviation':
                setId(id);
                appcontext.setStatusFCCD(true);
                break;
            case 'Carrier':
                setId(id);
                appcontext.setStatusFCCC(true);
                break;
            default:
                break;
        }

    }

    const handleEditCorreo = (id, nombre_archivo) => {
        setId(id);
        setNombreArchivo(nombre_archivo);
        appcontext.setStatusFCC(true);
    }

    const handleChangeStatus = async (id) => {
        switch (appcontext.initialCatalogo) {
            case 'Clientes':
                await changeStatus(id, 'clientes/changestatus');
                reloadtable('Clientes');
                break;
            case 'Codigos Sky':
                await changeStatus(id, 'codigosSky/changestatus');
                reloadtable('Codigos Sky');
                break;
            case 'Conexion Status':
                await changeStatus(id, 'codigosConexion/changestatus');
                reloadtable('Conexion Status');
                break;
            case 'Code Desviation':
                await changeStatus(id, 'codigosDesviacion/changestatus');
                reloadtable('Code Desviation');
                break;
            case 'Carrier':
                await changeStatus(id, 'carriers/changestatus');
                reloadtable('Carrier');
                break;
            default:
                break;
        }
    }

    const changeStatus = async (id, route) => {
        try {
            const obj = {}
            obj.USUARIO_ACTUALIZACION = sessionStorage.getItem("user");
            await axios.put(process.env.REACT_APP_API_URL_DE + `/api/${route}/${id}`, obj, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
                },
            });
            showNotification({
                title: 'Listo!',
                message: 'Estatus actualizado con exito',
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#25A661',
                        borderColor: '#25A661',

                        '&::before': { backgroundColor: theme.white },
                    },

                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })

            // Swal.fire({
            //     title: 'Listo!',
            //     text: 'Estatus actualizado con exito',
            //     icon: 'success',
            //     confirmButtonText: 'Ok!',
            //     customClass: {
            //         confirmButton: 'swal2-confirm'
            //     }
            // });

        } catch (err) {
            showNotification({
                title: 'Error!',
                message: err.message,
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#E93F19',
                        borderColor: 'E93F19',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
            // Swal.fire({
            //     title: 'Error!',
            //     text: err.response.data.error,
            //     icon: 'error',
            //     confirmButtonText: 'Ok!',
            //     customClass: {
            //         confirmButton: 'swal2-confirm'
            //     }
            // });
        }
    }

    return (
        <>
            <Group justify="flex-end" mt="md" mb="md">
                {appcontext.initialCatalogo != 'Correos de clientes' ? <Button variant="outline" color='#07E7CC' onClick={handleOpenForm}><FontAwesomeIcon icon={faPlus} /></Button> : ""}
            </Group>
            <Group justify="space-between">
                <Select
                    label="Registros por pagina"
                    value={pageSize.toString()}
                    onChange={(value) => setPageSize(parseInt(value, 10))}
                    data={[5, 10, 20, 30, 50].map((size) => ({ value: size.toString(), label: size.toString() }))}
                />
                <Title order={3}>{selectedv}</Title>
                <TextInput
                    label="Buscar"
                    placeholder="Buscar..."
                    value={filter}
                    onChange={(event) => { setFilter(event.currentTarget.value); setPage(1) }}
                />
            </Group>
            <Grid.Col span={12}>
                <Divider size="sm" />
            </Grid.Col>

            <Table.ScrollContainer minWidth={500} type="native">
                <Table striped highlightOnHover withTableBorder>
                    <Table.Thead >
                        <Table.Tr>
                            {columns.map(column => (
                                <Table.Th key={column} style={{ backgroundColor: '#035cf7', color: '#F2F2F0' }}>
                                    {column}
                                </Table.Th>
                            ))}
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {
                            paginatedData.map((row, rowIndex) => (
                                <Table.Tr key={rowIndex}>
                                    {columns.map(column => {

                                        if (column === 'ESTATUS') {
                                            return (
                                                <Table.Td key={column}>
                                                    {row[column] === true ? "ACTIVO" : "INACTIVO"}
                                                </Table.Td>
                                            );
                                        } else if (column !== 'ACCIONES') {
                                            return (
                                                <Table.Td key={column}>
                                                    {row[column]}
                                                </Table.Td>
                                            );
                                        }
                                    }
                                    )}
                                    <Table.Td>
                                        <Button.Group>
                                            {appcontext.initialCatalogo != 'Correos de clientes' ? <Button variant="outline" color='#035CF7' onClick={() => { handleEdit(row["_id"]) }}><FontAwesomeIcon icon={faPen} /></Button> : <Button variant="outline" color='#035CF7' onClick={() => { handleEditCorreo(row["_id"], row["NOMBRE ARCHIVO"]) }}><FontAwesomeIcon icon={faPen} /></Button>}
                                            {appcontext.initialCatalogo != 'Correos de clientes' ? <Button variant="outline" color='#FA0073' onClick={() => { handleChangeStatus(row["_id"]) }}><FontAwesomeIcon icon={faLock} /></Button> : ""}
                                        </Button.Group>
                                    </Table.Td>
                                </Table.Tr>
                            ))

                        }
                    </Table.Tbody>
                </Table>
            </Table.ScrollContainer>
            <Grid.Col span={12}>
                <Divider size="sm" />
            </Grid.Col>
            <Group justify="flex-end">
                <Pagination
                    total={totalPages}
                    page={page}
                    onChange={setPage}
                    styles={{ control: { margin: '0 5px' } }}
                />
                <div style={{ marginTop: '10px' }}>
                    Showing {startIndex + 1} to {(endIndex + 1) > totalRecords ? totalRecords : endIndex + 1} of {totalRecords} entries
                </div>
            </Group>
        </>
    );
};

export default DataTableComponent;