
import { useForm, isNotEmpty } from '@mantine/form';
import { Button, Group, TextInput, Modal } from '@mantine/core';
import { AppContext } from '../../../context/context';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { showNotification } from '@mantine/notifications';

const FormCliente = ({ id, reloadtable }) => {

  const appcontext = useContext(AppContext);
  const [title, setTitle] = useState('Nuevo')
  useEffect(() => {
    if (id != '' && appcontext.openedFCN) {
      getDataFromId(id);
      setTitle('Editar Cliente');
    } else {
      setTitle('Nuevo Cliente');
      form.reset();
    }
  }, [appcontext.openedFCN]);

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      CODIGO: '',
      NOMBRE: '',
      COMENTARIO: ''
    },

    validate: {
      CODIGO: isNotEmpty('Debes ingresar un valor'),
      NOMBRE: isNotEmpty('Debes ingresar un valor')
    },
  });


  const handleClickNewClient = async (values) => {
    try {      
      values.USUARIO_ACTUALIZACION = sessionStorage.getItem("user");      
      await axios.post(process.env.REACT_APP_API_URL_DE+'/api/clientes', values, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
        },
      });
      showNotification({
        title: 'Listo!',
        message: 'Cliente guardado con exito',
        color: '#fff',
        styles: (theme) => ({
          root: {
            backgroundColor: '#25A661',
            borderColor: '#25A661',
            '&::before': { backgroundColor: theme.white },
          },
          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
          },
        }),
        autoClose: false,
      })
      reloadtable('Clientes');
      form.reset();
    } catch (err) {
      showNotification({
        title: 'Error!',
        message: err.response?.data?.error ? err.response.data.error : err.message,
        color: '#fff',
        styles: (theme) => ({
          root: {
            backgroundColor: '#E93F19',
            borderColor: 'E93F19',
            '&::before': { backgroundColor: theme.white },
          },
          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
          },
        }),
        autoClose: false,
      })
    }
  }

  const handleClickUpdateClient = async (values) => {
    try {
      values.USUARIO_ACTUALIZACION = sessionStorage.getItem("user");
      await axios.put(process.env.REACT_APP_API_URL_DE + `/api/clientes/${id}`, values,{
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
        },
      });
      showNotification({
        title: 'Listo!',
        message: 'Cliente actualizado con exito',
        color: '#fff',
        styles: (theme) => ({
          root: {
            backgroundColor: '#25A661',
            borderColor: '#25A661',
            '&::before': { backgroundColor: theme.white },
          },
          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
          },
        }),
        autoClose: false,
      })
      reloadtable('Clientes');
      appcontext.setStatusFCN(false);
    } catch (err) {
      showNotification({
        title: 'Error!',
        message: err.response?.data?.error ? err.response.data.error : err.message,
        color: '#fff',
        styles: (theme) => ({
          root: {
            backgroundColor: '#E93F19',
            borderColor: 'E93F19',
            '&::before': { backgroundColor: theme.white },
          },
          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
          },
        }),
        autoClose: false,
      })
    }

  }

  const getDataFromId = async (id) => {
    try {
      var response=await axios.post(process.env.REACT_APP_API_URL_DE+'/api/clientes/oneclient', {id:id}, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
        },
      });
      
      let result= response.data;
      form.setValues({
        CODIGO: result.CODIGO,
        NOMBRE: result.NOMBRE,
        COMENTARIO: result.COMENTARIO
      });
    } catch (err) {
      showNotification({
        title: 'Error!',
        message: err.response?.data?.error ? err.response.data.error : err.message,
        color: '#fff',
        styles: (theme) => ({
          root: {
            backgroundColor: '#E93F19',
            borderColor: 'E93F19',
            '&::before': { backgroundColor: theme.white },
          },
          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
          },
        }),
        autoClose: false,
      })
    }
  }

  return (
    <Modal opened={appcontext.openedFCN} onClose={() => { appcontext.setStatusFCN(false); }} title={title}>
      <form onSubmit={form.onSubmit((values) => { id == '' ? handleClickNewClient(values) : handleClickUpdateClient(values) })}>
        <TextInput
          label="Codigo"
          placeholder="Codigo del cliente"
          withAsterisk
          key={form.key('CODIGO')}
          {...form.getInputProps('CODIGO')}
        />
        <TextInput
          label="Nombre"
          placeholder="Nombre del cliente"
          mt="md"
          withAsterisk
          key={form.key('NOMBRE')}
          {...form.getInputProps('NOMBRE')}
        />
        <TextInput
          label="Comentario"
          placeholder="Comentario"
          mt="md"
          key={form.key('COMENTARIO')}
          {...form.getInputProps('COMENTARIO')}
        />
        <Group justify="flex-end" mt="md">
          <Button type="button" variant='outline' onClick={() => { appcontext.setStatusFCN(false); }}>Cerrar</Button>
          <Button type="submit">Guardar</Button>
        </Group>
      </form>
    </Modal>
  );

}

export default FormCliente;