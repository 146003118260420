"use client"
import { Group } from '@mantine/core';
import logo from '../../../content/Images/aeromex_logo.svg';
import classes from './HeaderLogin.module.css';


const HeaderLogin = () => {
  return (
    <header className={classes.header}>
      <div className={classes.inner}>
        <Group>
          <img
            src={logo}
            alt="logo"
            className={classes.aeroLogo}
          />
        </Group>
      </div>
    </header>
  );
}

export default HeaderLogin