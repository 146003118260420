import { Accordion, Button, Group } from '@mantine/core';
import classes from './Home.module.css';
import { AppContext } from '../../context/context';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFileExcel, faCircleNodes } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const buttons = [
  {
    id: 1, emoji: <FontAwesomeIcon icon={faBook} />, nombre: 'Catálogos', subbtn: [
      { id: 1, nombre: 'Clientes', accion: { link: "/Catalogos" } },
      { id: 2, nombre: 'Codigos Sky', accion: { link: "/Catalogos" } },
      { id: 3, nombre: 'Correos de clientes', accion: { link: "/Catalogos" } },
      { id: 7, nombre: 'Conexion Status', accion: { link: "/Catalogos" } },
      { id: 9, nombre: 'Code Desviation', accion: { link: "/Catalogos" } },
      { id: 10, nombre: 'Carrier', accion: { link: "/Catalogos" } },
    ]
  },
  {
    id: 2, emoji: <FontAwesomeIcon icon={faFileExcel} />, nombre: 'Reportes', subbtn: [
      { id: 11, nombre: 'Reporte Embarques', accion: { link: "/ReporteEmbarques" } }
    ]
  },
  {
    id: 3, emoji: <FontAwesomeIcon icon={faCircleNodes} />, nombre: 'Conexiones', subbtn: [
      { id: 12, nombre: 'Tramos Interlineales', accion: { link: "/Interlineales" }  }
    ]
  }
]

const Home = () => {
  const appcontext = useContext(AppContext);
  const navigate = useNavigate();
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    //appcontext.setCurrentUser('');
    const fetchAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();        
        sessionStorage.setItem("token",token) 
        sessionStorage.setItem("user",user.nickname)  
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchAccessToken();

  }, [])

  const handeClic = (nombre, catalogo) => {
    switch (nombre) {
      case 'Catálogos':
        appcontext.setInitialCatalog(catalogo);
        navigate('/Catalogos');
        break;
      case 'Reportes':
        navigate('/ReporteEmbarques');
        break;
      case 'Conexiones':
        navigate('/Interlineales')
        break;
    }
  }



  const items = buttons.map((item) => (
    <Accordion.Item key={item.id} value={item.nombre}>
      <Accordion.Control icon={item.emoji}>{item.nombre}</Accordion.Control>
      <Accordion.Panel>{item.subbtn.map(item2 => (<Group justify="center" key={item2.nombre}><Button variant="outline" onClick={() => handeClic(item.nombre, item2.nombre)} className={classes.btnprimary}>{item2.nombre}</Button></Group>))}</Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <div className={classes.main}>
      <Accordion >
        {items}
      </Accordion>
    </div>
  );

}

export default Home;