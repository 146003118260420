import React, { useState } from 'react';

export const AppContext = React.createContext({

});

const AppContextProvider = ({ children }) => {
    //variables de toda la pagina
    const [currentUser, setCurrentUser] = useState('ILHDZR');

    //variables de catalogos
    const [initialCatalogo, setInitialCatalog] = useState('');
    const [openedFCN, setStatusFCN] = useState(false);
    const [openedFCS, setStatusFCS] = useState(false);
    const [openedFCC, setStatusFCC] = useState(false);
    const [openedFCCS, setStatusFCCS] = useState(false);
    const [openedFCCD, setStatusFCCD] = useState(false);
    const [openedFCCC, setStatusFCCC] = useState(false);
    const [openedFNR, setStatusFNR] = useState(false);
    const [openedFNI, setStatusFNI] = useState(false);
    const [token, setToken] = useState("");



    return (
        <AppContext.Provider value={{
            initialCatalogo, setInitialCatalog, openedFCN, setStatusFCN, currentUser,
            setCurrentUser, openedFCS, setStatusFCS, openedFCC, setStatusFCC, openedFCCS,
            setStatusFCCS, openedFCCD, setStatusFCCD, openedFCCC, setStatusFCCC,
            setStatusFNR, openedFNR, setStatusFNI, openedFNI, setToken, token
        }}>
            {children}
        </AppContext.Provider>
    );
}

export default AppContextProvider
