
import { useForm, isNotEmpty } from '@mantine/form';
import { Button, Group, TextInput, Modal } from '@mantine/core';
import { AppContext } from '../../../context/context';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { showNotification } from '@mantine/notifications';

const FormDesviacion = ({ id, reloadtable }) => {

    const appcontext = useContext(AppContext);
    const [title, setTitle] = useState('Nuevo')

    useEffect(() => {
        if (id != '' && appcontext.openedFCCD) {
            getDataFromId(id);
            setTitle('Editar codigo de desviacion');
        } else {
            setTitle('Nuevo codigo de desviacion');
            form.reset();
        }
    }, [appcontext.openedFCCD]);

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            CODIGO: '',
            DESCRIPCION: '',
            COMENTARIO: ''
        },

        validate: {
            CODIGO: isNotEmpty('Debes ingresar un valor'),
            DESCRIPCION: isNotEmpty('Debes ingresar un valor')
        },
    });


    const handleClickNew = async (values) => {
        try {
            values.USUARIO_ACTUALIZACION = sessionStorage.getItem("user");
            await axios.post(process.env.REACT_APP_API_URL_DE + '/api/codigosDesviacion', values, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
                }
            });
            showNotification({
                title: 'Listo!',
                message: 'Codigo de desviacion guardado con exito',
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#25A661',
                        borderColor: '#25A661',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
            reloadtable('Code Desviation');
            form.reset();
        } catch (err) {
            showNotification({
                title: 'Error!',
                message: err.response?.data?.error ? err.response.data.error : err.message,
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#E93F19',
                        borderColor: 'E93F19',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
        }
    }

    const handleClickUpdate = async (values) => {
        try {
            values.USUARIO_ACTUALIZACION = sessionStorage.getItem("user");
            await axios.put(process.env.REACT_APP_API_URL_DE + `/api/codigosDesviacion/${id}`, values, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
                }
            });
            showNotification({
                title: 'Listo!',
                message: 'Codigo de desviacion actualizado con exito',
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#25A661',
                        borderColor: '#25A661',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
            reloadtable('Code Desviation');
            appcontext.setStatusFCCD(false);
        } catch (err) {
            showNotification({
                title: 'Error!',
                message: err.response?.data?.error ? err.response.data.error : err.message,
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#E93F19',
                        borderColor: 'E93F19',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
        }
    }

    const getDataFromId = async (id) => {
        try {
            var response = await axios.post(process.env.REACT_APP_API_URL_DE + '/api/codigosDesviacion/onecode', { id: id }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
                }
            });

            let result = response.data;
            form.setValues({
                CODIGO: result.CODIGO,
                DESCRIPCION: result.DESCRIPCION,
                COMENTARIO: result.COMENTARIO
            });
        } catch (err) {
            showNotification({
                title: 'Error!',
                message: err.response?.data?.error ? err.response.data.error : err.message,
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#E93F19',
                        borderColor: 'E93F19',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
        }
    }

    return (
        <Modal opened={appcontext.openedFCCD} onClose={() => { appcontext.setStatusFCCD(false); }} title={title}>
            <form onSubmit={form.onSubmit((values) => { id == '' ? handleClickNew(values) : handleClickUpdate(values) })}>
                <TextInput
                    label="Codigo Desviacion"
                    placeholder="Codigo"
                    mt="md"
                    withAsterisk
                    key={form.key('CODIGO')}
                    {...form.getInputProps('CODIGO')}
                />
                <TextInput
                    label="Descripcion"
                    placeholder="Descripcion del codigo"
                    mt="md"
                    withAsterisk
                    key={form.key('DESCRIPCION')}
                    {...form.getInputProps('DESCRIPCION')}
                />
                <TextInput
                    label="Comentario"
                    placeholder="Comentario"
                    mt="md"
                    key={form.key('COMENTARIO')}
                    {...form.getInputProps('COMENTARIO')}
                />
                <Group justify="flex-end" mt="md">
                    <Button type="button" variant='outline' onClick={() => { appcontext.setStatusFCCD(false); }}>Cerrar</Button>
                    <Button type="submit">Guardar</Button>
                </Group>
            </form>
        </Modal>
    );

}

export default FormDesviacion;