import 'dayjs/locale/es';
import DataTableReportes from '../CustomComponents/DataTableInterlineales';
import { Card, Grid, Button, Group, Box, LoadingOverlay, Modal, FileInput } from '@mantine/core';
import classes from './Interlineales.module.css';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../context/context';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { DatePickerInput, DatesProvider } from '@mantine/dates';
import '@mantine/dates/styles.css';
import axios from 'axios';
import * as XLSX from 'xlsx';
import FormInfo from './FormInfo/FormInfo';
import FormRecord from './FormRecord/FormRecord';

const Interlineales = () => {

    const appcontext = useContext(AppContext);
    const [file, setFile] = useState(null);

    const [dataofTable, setDataofTable] = useState([]);
    const [visible, setIsVisible] = useState(false);
    const [opened, { open, close }] = useDisclosure(false);
    const headers = ['MES', 'Date', 'PREFIX', 'GUÍA', 'QTY', 'Weigth', 'Volume', 'Vol kg', 'Tons', 'Desc', 'SHC', 'DEP', 'HUB', 'DES', 'CR 1', 'RUTA 1', 'VUELO 1', 'FECHA 1', 'ETD 1', 'ETA 1', 'CR 2', 'RUTA 2', 'VUELO 2', 'FECHA 2', 'ETD 2', 'ETA 2', 'Forwarder', 'Complete Documents', 'Exceed 1M', 'T1 Status', 'CONEXIÓN STATUS', 'Codigo de Desviación'];
    const [value, setValue] = useState([]);

    //Carriers
    const [dataCarriers, setDataCarriers] = useState([]);

    //CS
    const [dataCS, setDataCS] = useState([]);

    //CodeDesviation
    const [dataCodeDesviation, setDataCodeDesviation] = useState([]);

    const [id, setId] = useState('');

    useEffect(() => {
        getCatalogs();
        return () => {
            // Clear out the data on unmount (optional)
            setDataofTable([]);
        };
    }, []);

    const handleFileUpload = (e) => {
        setFile(e);
    };

    const handleGetTableData = async () => {
        try {
            setIsVisible(true);
            const response = await fetch(process.env.REACT_APP_API_URL_DE + `/api/interlineales/${value}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            let result = await response.json();
            if (result.data.length == 0) {
                throw new Error("No hay datos cargados");
            }
            setDataofTable(result);
        } catch (err) {
            showNotification({
                title: 'Error!',
                message: err.response?.data?.error ? err.response.data.error : err.message,
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#E93F19',
                        borderColor: 'E93F19',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
        } finally {
            setIsVisible(false);
        }
    }

    const arraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }

        return true;
    }

    const handleSendData = async () => {
        setIsVisible(false);
        if (file) {
            close();
            const data = await file.arrayBuffer();
            /* data is an ArrayBuffer */
            const workbook = XLSX.read(data, { type: 'array' });

            const allSheetData = workbook.SheetNames.map(sheetName => {
                const worksheet = workbook.Sheets[sheetName];

                // Convert sheet to JSON where each cell is read as a string
                const sheetData = XLSX.utils.sheet_to_json(worksheet, {
                    header: 1,        // Each row is returned as an array
                    raw: false        // Treat all cells as strings
                });

                // Convert cells to strings explicitly if needed
                return sheetData.map(row => row.map(cell => String(cell)));
            });
            const jsonData = allSheetData.flat();

            try {
                setIsVisible(true);
                if (!arraysEqual(jsonData[0], headers)) {
                    throw new Error('El formato no coincide con el establecido');
                }
                jsonData.shift();
                var x = jsonData;
                var values = {};
                values.USUARIO_ACTUALIZACION = sessionStorage.getItem("user");
                values.arr_in = x;
                console.log(x.length);
                const response = await axios.post(process.env.REACT_APP_API_URL_DE + '/api/interlineales', values, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include the Bearer token
                    }
                });
                showNotification({
                    title: 'Listo!',
                    message: response.data ? response.data : 'Archivo guardado con exito',
                    color: '#fff',
                    styles: (theme) => ({
                        root: {
                            backgroundColor: '#25A661',
                            borderColor: '#25A661',
                            '&::before': { backgroundColor: theme.white },
                        },
                        title: { color: theme.white },
                        description: { color: theme.white },
                        closeButton: {
                            color: theme.white,
                        },
                    }),
                    autoClose: false,
                })

            } catch (err) {
                showNotification({
                    title: 'Error!',
                    message: err.response.data.error ? err.response.data.error : err.message,
                    color: '#fff',
                    styles: (theme) => ({
                        root: {
                            backgroundColor: '#E93F19',
                            borderColor: 'E93F19',
                            '&::before': { backgroundColor: theme.white },
                        },
                        title: { color: theme.white },
                        description: { color: theme.white },
                        closeButton: {
                            color: theme.white,
                        },
                    }),
                    autoClose: false,
                })

            } finally {
                setIsVisible(false);
            }
        };
    }

    //getCatalogs
    const getCatalogs = async () => {
        try {
            setIsVisible(true);
            const response = await fetch(process.env.REACT_APP_API_URL_DE + '/api/interlineales/initCatalogs', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            let result = await response.json();                     
            setDataCarriers(result[0].Items.filter(item => item.ESTATUS === true).map(item => item.CODIGO))                                
            setDataCS(result[1].Items.filter(item => item.ESTATUS === true).map(item => item.DESCRIPCION))                            
            setDataCodeDesviation(result[2].Items.filter(item => item.ESTATUS === true).map(item => item.CODIGO + ' (' + item.DESCRIPCION + ')'));

        } catch (err) {
            showNotification({
                title: 'Error!',
                message: err.response?.data?.error ? err.response.data.error : err.message,
                color: '#fff',
                styles: (theme) => ({
                    root: {
                        backgroundColor: '#E93F19',
                        borderColor: 'E93F19',
                        '&::before': { backgroundColor: theme.white },
                    },
                    title: { color: theme.white },
                    description: { color: theme.white },
                    closeButton: {
                        color: theme.white,
                    },
                }),
                autoClose: false,
            })
        } finally {
            setIsVisible(false);
        }
    }

    return (
        <Box pos="relative">
            <LoadingOverlay
                visible={visible}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{ color: '#FA0073', type: 'bars', size: 'xl' }}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}
            />
            <div className={classes.main}>

                <Grid margin="md">
                    <Grid.Col>
                        <Card shadow="sm" padding="lg" radius="md" withBorder justify="space-between">
                            <Group justify='center' mt="md">
                                <DatesProvider settings={{ locale: 'es' }}>
                                    <DatePickerInput
                                        type="range"
                                        label="Rango de fecha para busqueda"
                                        placeholder="Selecciona un rango de fechas"
                                        value={value}
                                        onChange={setValue}
                                        valueFormat="DD/MM/YYYY"
                                    />
                                </DatesProvider>

                                <Button.Group>
                                    <Button variant="outline" color='#035CF7' onClick={handleGetTableData}>
                                        Buscar
                                    </Button>
                                    <Button variant="outline" color='#035CF7' onClick={() => { appcontext.setStatusFNR(true); setId(''); }}>
                                        Nuevo Registro
                                    </Button>
                                    <Button variant="outline" color='#035CF7' onClick={open}>
                                        Cargar Reporte XLSX
                                    </Button>
                                </Button.Group>
                            </Group>
                        </Card>
                    </Grid.Col>
                    {dataofTable.length === 0 ? "" : <Grid.Col>
                        <div style={{ padding: '20px' }}>
                            <DataTableReportes data={dataofTable.data} setId={setId} conteocr={dataofTable.conteocr} conteoh={dataofTable.conteohubs} conteov={dataofTable.conteovuelos} conteocs={dataofTable.conteocs} />
                        </div>
                    </Grid.Col>}
                </Grid>
            </div>

            <Modal opened={opened} onClose={close} title="Carga por xlsx">
                <FileInput
                    variant="filled"
                    size="md"
                    radius="xl"
                    label="Selecciona un archivo"
                    description="Carga un archivo xlsx"
                    placeholder="Selecciona un archivo"
                    onChange={handleFileUpload}
                />
                <Group justify="flex-end" mt="md">
                    <Button type="button" variant='outline' onClick={close}>Cerrar</Button>
                    <Button type="button" onClick={handleSendData}>Enviar</Button>
                </Group>
            </Modal>

            <FormRecord id={id} reloadTable={handleGetTableData} dataCarriers={dataCarriers} dataCS={dataCS} dataCodeDesviation={dataCodeDesviation} setIsVisible={setIsVisible} fechas={value} />
            <FormInfo id={id} />

        </Box>
    );
}

export default Interlineales