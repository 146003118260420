import { useForm } from '@mantine/form';
import { Button, Group, TextInput, Modal } from '@mantine/core';
import { AppContext } from '../../../context/context';
import { useContext, useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';

const FormInfo = ({ id }) => {

  const appcontext = useContext(AppContext);

  useEffect(() => {
    if (id != '' && appcontext.openedFNI) {
      getDataFromId(id);
    }
  }, [appcontext.openedFNI]);

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      COMPLETEDOCS: '',
      CODEDESV: '',
      LASTUPDATE: '',
      IC: ''
    },
  });

  const getDataFromId = async (id) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL_DE + '/api/interlineales/extrainfo/' + id, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
          'Content-Type': 'application/json',
        }
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      let result = await response.json();
      form.setValues({
        COMPLETEDOCS: result.completeDocuments,
        CODEDESV: result.codeDesviation,
        LASTUPDATE: result.FECHA_ACTUALIZACION.substring(0, 10) + ' ' + result.USUARIO_ACTUALIZACION,
        IC: result.interline
      });
    } catch (err) {
      showNotification({
        title: 'Error!',
        message: err.response?.data?.error ? err.response.data.error : err.message,
        color: '#fff',
        styles: (theme) => ({
          root: {
            backgroundColor: '#E93F19',
            borderColor: 'E93F19',
            '&::before': { backgroundColor: theme.white },
          },
          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
          },
        }),
        autoClose: false,
      })
    }
  }

  return (
    <Modal opened={appcontext.openedFNI} onClose={() => { appcontext.setStatusFNI(false); }} title="Informacion">
      <form>
        <TextInput
          label="COMPELETE DOCS"
          readOnly
          key={form.key('COMPLETEDOCS')}
          {...form.getInputProps('COMPLETEDOCS')}
        />
        <TextInput
          label="CODE DESVIATION"
          mt="md"
          readOnly
          key={form.key('CODEDESV')}
          {...form.getInputProps('CODEDESV')}
        />
        <TextInput
          label="LAST UPDATE"
          mt="md"
          readOnly
          key={form.key('LASTUPDATE')}
          {...form.getInputProps('LASTUPDATE')}
        />
        <TextInput
          label="INTERLINE CONEXION"
          mt="md"
          readOnly
          key={form.key('IC')}
          {...form.getInputProps('IC')}
        />
        <Group justify="flex-end" mt="md">
          <Button type="button" variant='outline' onClick={() => { appcontext.setStatusFNI(false); }}>Cerrar</Button>
        </Group>
      </form>
    </Modal>
  );

}

export default FormInfo;