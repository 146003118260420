import { useState, useContext } from 'react';
import { Paper, Button, Title, TextInput, PasswordInput } from '@mantine/core';
import classes from './Login.module.css';
import HeaderLogin from '../Shared/HeaderLogin/HeaderLogin';
import Footer from '../Shared/Footer/Footer'
import { useAuth0 } from '@auth0/auth0-react';
import { useForm, isNotEmpty } from '@mantine/form';
import axios from 'axios';
import { AppContext } from '../../context/context';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';


const Login = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      usr: '',
      password: '',
    },


  });


  const handleSubmit = async (e) => {
    try {
      const obj = {"username":e.usr, "password":e.password}
      const response = await axios.post(process.env.REACT_APP_API_URL_DE+"/api/login", obj);
      const { token } = response.data;      
      sessionStorage.setItem("token", token)
      sessionStorage.setItem("user", e.usr)
      console.log("inicia sesion");
      navigate('/Home');
    } catch (err) {
      console.log(err);
      showNotification({
        title: 'Error!',
        message: "usuario o password incorrectos",//err.response.data.error ? err.response.data.error : err.message,
        color: '#fff',
        styles: (theme) => ({
          root: {
            backgroundColor: '#E93F19',
            borderColor: 'E93F19',
            '&::before': { backgroundColor: theme.white },
          },
          title: { color: theme.white },
          description: { color: theme.white },
          closeButton: {
            color: theme.white,
          },
        }),
        autoClose: false,
      })
    }
  };
  return (
    <>
      <HeaderLogin />
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0} p={30}>
          <Title order={2} className={classes.title} ta="center" mt="md" mb={50}>
            Bienvenido a SUCI
          </Title>
          <form onSubmit={form.onSubmit((values) => { handleSubmit(values) })}>
            <TextInput
              label="Usuario"
              placeholder="Usuario"
              key={form.key('usr')}
              {...form.getInputProps('usr')}
            />
            <PasswordInput
              label="Password"
              placeholder="Password"
              key={form.key('password')}
              {...form.getInputProps('password')}
            />
            <Button fullWidth mt="xl" color='#020c41' size="md" className={classes.amprimary} type="submit">
              Login
            </Button>
          </form>
          <Button fullWidth mt="xl" size="md" className={classes.amprimary} onClick={() => { loginWithRedirect() }} >
            Login with gateone
          </Button>
        </Paper>
      </div>
      <Footer />
    </>
  );
}

export default Login