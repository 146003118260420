import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, MantineProvider } from '@mantine/core';
import React from 'react';
import ProtectedRoute from './components/ProtectedRoutes/page';
import Login from './components/Login/page';
import Home from './components/Home/page';
import Catalogos from './components/Catalogos/page';
import ReporteEmbarques from './components/ReporteEmbarques/page';
import Interlineales from './components/Interlineales/page';
import ErrorPage from './components/ErrorPage/page';
import AppContextProvider from './context/context';
import { Notifications } from '@mantine/notifications'; // Import from @mantine/notifications
import '@mantine/notifications/styles.css';

import './App.css';

const theme = createTheme({
});

function App() {
  return (
    <MantineProvider theme={theme}>
      <Notifications position="top-right" zIndex={999} />
      <AppContextProvider>
        <BrowserRouter>          
            <Routes>
              <Route path='/' element={<Login />}> </Route>
              <Route element={<ProtectedRoute />}>
                <Route path="/Home" element={<Home />} />
                <Route path='/Catalogos' element={<Catalogos />}></Route>
                <Route path='/ReporteEmbarques' element={<ReporteEmbarques />}></Route>
                <Route path='/Interlineales' element={<Interlineales />}></Route>
              </Route>
              <Route path='*' element={<ErrorPage/>}></Route>
            </Routes>          
        </BrowserRouter>
      </AppContextProvider>

    </MantineProvider>
  );
}

export default App;
